module.exports = {
  siteTitle: 'An Pham Resume', // <title>
  manifestName: 'An Pham Resume',
  manifestShortName: 'Resume', // max 12 characters
  manifestStartUrl: '/resume',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  firstName: 'An',
  lastName: 'Pham',
  // social
  socialLinks: [
    {
      icon: 'fa-gitlab',
      name: 'Gitlab',
      url: 'https://gitlab.com/Anphamthanh',
    },
    {
      icon: 'fa-linkedin-in',
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/an-pham-atl/',
    },
  ],
  email: 'anphamthanh@yahoo.com',
  phone: 'xxx-xxxxxxx',
  address: 'Ho Chi Minh City, Viet Nam',
};
